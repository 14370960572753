// extracted by mini-css-extract-plugin
export var btnDefault = "team-management-module--btn-default--mpaLV";
export var attoiconJobs = "team-management-module--attoicon-jobs--54hzp";
export var attoiconNotes = "team-management-module--attoicon-notes--Bl8JQ";
export var attoiconTimesheets = "team-management-module--attoicon-timesheets--vOLKS";
export var attoiconTimesheetsRounded1 = "team-management-module--attoicon-timesheets-rounded1--jqWcR";
export var attoiconMobileTt = "team-management-module--attoicon-mobile-tt--SAOOq";
export var attoiconNamedSites = "team-management-module--attoicon-named-sites--rSFMe";
export var attoiconProfitableBids = "team-management-module--attoicon-profitable-bids--GYBYG";
export var attoiconDepartments = "team-management-module--attoicon-departments--Tx1kr";
export var attoiconTimeline = "team-management-module--attoicon-timeline--gBgOE";
export var attoiconFilters = "team-management-module--attoicon-filters--+vdT3";
export var attoiconVerified = "team-management-module--attoicon-verified--EhaAt";
export var attoiconTimezone = "team-management-module--attoicon-timezone--+6u-n";
export var attoiconManualEntries = "team-management-module--attoicon-manual-entries--4jRkT";
export var attoiconJobCosting = "team-management-module--attoicon-job-costing--hhh9A";
export var attoiconLocationTracking = "team-management-module--attoicon-location-tracking--MfMVR";
export var attoiconBreak = "team-management-module--attoicon-break--nBi0B";
export var attoiconNightShift = "team-management-module--attoicon-night-shift--kOJru";
export var attoiconOvertime = "team-management-module--attoicon-overtime--ecnAY";
export var attoiconTimeTracking1 = "team-management-module--attoicon-time-tracking1--TF5qC";
export var attoiconTimeTracking = "team-management-module--attoicon-time-tracking--CV9v7";
export var attoiconLocationHistory = "team-management-module--attoicon-location-history--jTh8c";
export var attoiconPrivacy = "team-management-module--attoicon-privacy--R6h4T";
export var attoiconTimeEntryHistory = "team-management-module--attoicon-time-entry-history--jXx2d";
export var attoiconCustomize = "team-management-module--attoicon-customize--vrL06";
export var attoiconRoundedClock = "team-management-module--attoicon-rounded-clock--FHF5B";
export var attoiconTimeOff = "team-management-module--attoicon-time-off--BCbKL";
export var attoiconNamedLocations = "team-management-module--attoicon-named-locations--OJ4li";
export var attoiconSandClock = "team-management-module--attoicon-sand-clock--2Wr-t";
export var attoiconBattery = "team-management-module--attoicon-battery--CQ7Yo";
export var attoiconSupport = "team-management-module--attoicon-support--NxnZ9";
export var attoiconHelpSupport = "team-management-module--attoicon-help-support--IVs35";
export var attoiconWebTt = "team-management-module--attoicon-web-tt--RyXIP";
export var attoiconArchive = "team-management-module--attoicon-archive--6jISE";
export var attoiconEmail = "team-management-module--attoicon-email--BjTQ8";
export var attoiconKiosk = "team-management-module--attoicon-kiosk--mIkfQ";
export var attoiconShare = "team-management-module--attoicon-share--FYwr+";
export var attoiconCrew = "team-management-module--attoicon-crew--1fY9G";
export var attoiconTeamActivity = "team-management-module--attoicon-team-activity--hJqXw";
export var attoiconTeam = "team-management-module--attoicon-team--Tk6XS";
export var attoiconWages = "team-management-module--attoicon-wages--REuGf";
export var attoiconNotification = "team-management-module--attoicon-notification--aNzUU";
export var attoiconAvatar = "team-management-module--attoicon-avatar--J7dMR";
export var attoiconViewMap = "team-management-module--attoicon-view-map--AE-k7";
export var attoiconMovementTracking = "team-management-module--attoicon-movement-tracking---GnhY";
export var attoiconWageEstimates = "team-management-module--attoicon-wage-estimates--7sY6H";
export var attoiconWageEstimatesBold = "team-management-module--attoicon-wage-estimates-bold--OAFlr";
export var attoiconClose = "team-management-module--attoicon-close--narb9";
export var attoiconPlus = "team-management-module--attoicon-plus--Mar-A";
export var attoiconMinus = "team-management-module--attoicon-minus--+qQ53";
export var attoiconTick = "team-management-module--attoicon-tick--5Ns6G";
export var attoiconArrowLeft = "team-management-module--attoicon-arrow-left--pDgfv";
export var attoiconArrowRight = "team-management-module--attoicon-arrow-right--PjTu-";
export var attoiconArrowDown = "team-management-module--attoicon-arrow-down--FjtSH";
export var attoiconArrowUp = "team-management-module--attoicon-arrow-up--CEVHM";
export var attoiconPlay = "team-management-module--attoicon-play--qiD4b";
export var attoiconLongArrow = "team-management-module--attoicon-long-arrow--OOWih";
export var behindMask = "team-management-module--behindMask--bP1ys";
export var productContainer = "team-management-module--productContainer--jbBFt";
export var teamActivityContainer = "team-management-module--teamActivityContainer--IUSa4";
export var readyMadeTitle = "team-management-module--readyMadeTitle--+G++U";
export var roundImagesContainer = "team-management-module--roundImagesContainer--E7utz";
export var learnMoreContainer = "team-management-module--learnMoreContainer--zS2HA";
export var checkListContainer = "team-management-module--checkListContainer--Uqztb";