import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';

import HeaderComponent from '@components/molecules/header';
import MainTitleCard from '@components/molecules/main-title-card';
import Article from '@components/molecules/article';
import Seo from '@components/molecules/seo';
import Divider from '@components/atoms/divider';
import { apiUrl } from '@helpers';
import { container } from '@styles/main.module.scss';

import { productContainer, behindMask } from './team-management.module.scss';

const Modal = loadable(() => import('@components/molecules/modal'));
const FooterComponent = loadable(() => import('@components/molecules/footer'));
const SubscribeBanner = loadable(() => import('@components/molecules/subscribe-banner'));

const TeamManagement = () => {
  const Intl = useIntl();

  const [showDialog, setShowDialog] = useState(false);
  const openModal = () => setShowDialog(true);
  const closeModal = () => setShowDialog(false);
  const [values, setValues] = useState(null);

  const toggleDeleteInvite = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: data.email }),
    };
    fetch(`${apiUrl}/delete-invite`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        closeModal();
        setValues(data);
        setTimeout(() => openModal(), 2000);
      });
  };

  const formSuccessState = (val) => {
    if (val?.action !== 'delete') {
      closeModal();
      setValues(val);
      setTimeout(() => {
        openModal();
      }, 500);
    } else {
      toggleDeleteInvite(val);
    }
  };

  const timeOffRequestTitle = (
    <>
      Easily manage <br /> time off requests
    </>
  );

  return (
    <div className={`${container} ${productContainer}`}>
      <Modal
        close={closeModal}
        showDialog={showDialog}
        hasValues={values}
        onDelete={toggleDeleteInvite}
        setFormValues={(formValues) => formSuccessState(formValues)}
      />
      <Seo
        title={Intl.formatMessage({ id: 'pages.productOverview.teamManagementMetaTitle' })}
        description={Intl.formatMessage({
          id: 'pages.productOverview.teamManagementMetaDescription',
        })}
      />
      <HeaderComponent headerStyle="pricingHeader" />
      <span className={behindMask} />
      <MainTitleCard
        maxParagraphWidth={760}
        title={Intl.formatMessage({ id: 'pages.productOverview.teamManagementBanner' })}
        subtitle={Intl.formatMessage({ id: 'pages.productOverview.teamManagementBannerDesc' })}
      />
      <Divider className="style3" />
      <Article
        title="Trade your shift or find someone to cover you"
        description="Instead of calling a colleague, and hoping they pick up, you can easily send them a shift trade or cover request from the app. Send the request to multiple colleagues at once and get notified when someone accepts instantly."
        image="roofing-two"
        maxWidth={398}
        imagePadding="6rem 0"
        imageWidth={398}
        imageHeight={413}
      />
      <Article
        isSwapped
        title={timeOffRequestTitle}
        description="Stay on top of your team’s time off and avoid scheduling conflicts. Managers approve or reject time off requests as they come in and notify their staff right away."
        image="mobile-workers"
        maxWidth={300}
        imagePadding="6rem 0"
        imageWidth={300}
        imageHeight={460}
      />
      <Article
        title="Enter availability to simplify scheduling"
        description="Keep track of your staff’s availability in real-time. Streamline your scheduling process and make sure you only assign shifts to staff that are available to work."
        image="employees-together"
        maxWidth={304}
        imagePadding="6rem 0"
        imageWidth={304}
        imageHeight={459}
      />
      <Divider className="style3" />
      <StaticImage
        src="../../../images/running-business.png"
        width={1140}
        height={460}
        alt="Running Business"
        quality={98}
      />
      <Divider className="style3" />
      <Article
        isSwapped
        title="Find out who’s working and who’s available"
        description="Has business picked up and you’re not fully covered? Quickly find out who’s available to work and assign them a new shift. Or find out who’s on the clock, who hasn’t shown up yet and who’s scheduled to start later."
        image="office-workers"
        maxWidth={398}
        imagePadding="6rem 0"
        imageWidth={398}
        imageHeight={413}
      />
      <Article
        title="Get helpful reminders that keep you updated"
        description="Make a great impression at work and stay in the loop of the latest shift changes. Get instant notifications whenever the schedule is published, your shift is updated, a shift is about to start, or a colleague asks to trade your shift."
        image="never-forget"
        maxWidth={380}
        imagePadding="6rem 0"
        imageWidth={380}
        imageHeight={388}
      />
      <Divider className="style3" />
      <SubscribeBanner
        title={Intl.formatMessage({ id: 'pages.pricing.teamManagementTitle' })}
        subtitle={Intl.formatMessage({ id: 'pages.pricing.subscribeBannerDesc' })}
        placeholder={Intl.formatMessage({ id: 'pages.miscellaneous.typeYourEmail' })}
        checkItemOne={Intl.formatMessage({ id: 'pages.miscellaneous.noCreditCard' })}
        checkItemTwo={Intl.formatMessage({ id: 'pages.miscellaneous.14DaysTrial' })}
        checkItemThree={Intl.formatMessage({ id: 'pages.miscellaneous.cancelAnytime' })}
      />
      <FooterComponent />
    </div>
  );
};

export default TeamManagement;
